<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>     
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Estados civiles'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.nombre}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text>
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Nombre" 
                                                    class="pa-0 ma-0" 
                                                    v-model="estadoCivil.nombre" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-layout row>
                                    <v-flex class="justify-center">
                                        <div class="btnModal">
                                            <v-btn class="btnGuardar modal-pull-right" @click="guardar()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                                            <button class="btnCerrar modal-pull-right" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/EstadosCiviles';
import Notify from '@/plugins/notify';
import apiEstadosCiviles from "../api/estadosCiviles";


export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            datosLogin  : null,
            rol         : null,
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            estadoCivil : {
                id      : null,
                nombre  : null
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            imagen          : '',
            nombre          : null
        }
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            this.$apollo.queries.tipo_nominas;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoading  = true;

            if(accion == "add"){
                this.tituloModal = "Registrar estado civil";
                this.imagen     = "/static/modal/estadoCivilCreate.svg"
            } else {
                this.tituloModal = "Actualizar de estado civil";
                this.imagen     = "/static/modal/estadoCivilUpdate.svg"
                this.estadoCivil.id         = data.id;
                this.estadoCivil.nombre     = data.nombre;
            }
            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.estadoCivil    = {
                id      : null,
                nombre  : null
            }
            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
                this.nombre = null;
            });
        },
        setFilters() {
            this.filters = {AND:[]};
            let nombre = this.nombre;

            this.isLoading = true;

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }
            
            this.$apollo.queries.estados_civiles.refetch();
            this.resetValues();
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el estado civil " + data.nombre + "?",
                "Eliminar",
                () => {
                    apiEstadosCiviles.deleteEstadoCivil(data.id).then(response => {
                        Notify.Success("Operación exitosa", "El estado civil ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.estados_civiles.refetch();
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    });
                }
            )
        },
       
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(this.accion === "add") {
                    apiEstadosCiviles.addEstadoCivil(this.estadoCivil)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "Se creó el nuevo estado civil satisfactoriamente.");
                        this.$apollo.queries.estados_civiles.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                }
                else if(this.accion === "update") {
                    apiEstadosCiviles.updateEstadoCivil(this.estadoCivil)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "Se actualizó el estado civil satisfactoriamente.");
                        this.$apollo.queries.estados_civiles.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                }
            });
        },
        redireccionar(){
            this.$router.push('/dashboard');
        }
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
    },
    mounted(){
        this.$apollo.queries.estados_civiles.skip = false;
    },
    apollo: {
        estados_civiles: {
            query       : queries.estadosCivilesTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading = true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true;
            },
            result({loading, error}) {
                this.isLoading    = false;
                this.tableData  = this.estados_civiles;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }

</style>
