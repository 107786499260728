import Vue from 'vue';

export default {
    getEstadosCiviles(parametros){
        return Vue.axios.get("catalogo/estados-civiles/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEstadoCivil(id){
        return Vue.axios.get("catalogo/estados-civiles/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateEstadoCivil(parametros){
        return Vue.axios.put("catalogo/estados-civiles", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteEstadoCivil(id){
        return Vue.axios.delete("catalogo/estados-civiles/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addEstadoCivil(parametros){
        return Vue.axios.post("catalogo/estados-civiles", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}