import gql from 'graphql-tag';

const estadosCivilesTableQuery = gql`
    query estados_civiles($whereConditions:EstadosCivilesWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!) {
        estados_civiles(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: DESC}]) {
            data {
                id,
                nombre
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { estadosCivilesTableQuery };

export default queries;
